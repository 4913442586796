<template>
  <div>
    <div v-for="(value, key) in followupList" :key="key">
      <div class="followup-item">
        <div class="followup-item__header">
          <span class="followup-item__header-no">{{ $t("单号") }}：{{ key }}</span>
        </div>
        <van-cell-group>
          <van-cell v-for="item in value" :key="item.id" :value="item.fFee ? item.fFee + ' ' + $t('铢') : '待入仓计费'"
            :title="$t(convertIssueType(item.issueType))" :label="item.issueType == 'insurance' && item.declareFee
              ? $t('保价金额') + ':' + item.declareFee : ''">
          </van-cell>
        </van-cell-group>

        <div v-if="imagesList[key]" class="image-wrapper">
          <van-image v-for="(img, index) in imagesList[key]" :key="img" fit="contain" @click="imagePreview(key, index)"
            class="followup-image" :src="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertIssueType } from "@/utils/dict";
import { getFollowUpList } from "@/service/api";
import { Toast, Cell, Image as VanImage, ImagePreview, CellGroup } from "vant";

export default {
  components: {
    [Cell.name]: Cell,
    [VanImage.name]: VanImage,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      followupList: [],
      imagesList: {},
    };
  },
  mounted() {
    const { cargoIds } = this.$route.query;
    this.queryAllFollowUp(cargoIds);
  },
  methods: {
    convertIssueType(value) {
      return convertIssueType(value);
    },
    imagePreview(key, index) {
      ImagePreview({
        images: this.imagesList[key],
        startPosition: index,
      });
    },
    async queryAllFollowUp(cargoIds) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中"),
      });
      const res = await getFollowUpList(cargoIds);
      if (res && res.success) {
        const groups = {};
        const images = {};
        res.data.forEach(function (o) {
          groups[o.trackingNo] = groups[o.trackingNo] || [];
          groups[o.trackingNo].push(o);
          images[o.trackingNo] = images[o.trackingNo] || [];
          if (o.images) {
            const ary = o.images.split(",");
            images[o.trackingNo].push(...ary);
          }
        })
        Object.keys(images).forEach((key, index) => {
          const imageAry = images[key];
          const newImageAry = imageAry.filter(function (item, index, self) {
            return self.indexOf(item) == index;
          });
          images[key] = newImageAry;
        })
        this.followupList = groups;
        this.imagesList = images;
      }
      Toast.clear();
    },
  },
};
</script>
<style lang="less" scoped>
.followup-item {
  border: 1px solid #f3f3f3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.followup-item__header {
  background-color: #f8f9fa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #000;
  font-size: 14px;
  padding: 10px;
  display: flex;
}

.followup-item__header-no {
  font-size: 16px;
}

.remark {
  .van-cell__value {
    min-width: 75%;
  }
}

.followup-image {
  /* border: 1px solid red; */
  /* flex: 1; */
  width: 33%;
  height: 100px;
}
</style>>